.crew-shifts {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow: hidden;
  gap: 16px;
}
.crew-shifts .crew-shifts-container {
  display: grid;
  /* overflow: hidden; */
  overflow-x: auto;
  overflow-y: hidden;
}

.crew-shifts .crew-shifts-header > div {
  display: grid;
  gap: 16px;
  margin: 24px 24px 0 24px;
  grid-template-columns: 1fr auto;
  align-items: center;
}

.crew-shifts .crew-shifts-header span {
  color: var(--medium-font-color);
  font-size: 14px;
  font-weight: 500;
  text-wrap: nowrap;
}

.crew-shifts .crew-shifts-header .actions {
  display: flex;
  gap: 8px;
  align-items: center;
}

.crew-shifts .crew-shifts-header .actions button svg {
  margin-right: 4px;
}

.crew-shifts .crew-shifts-content {
  display: grid;
  margin: 0 24px 24px 24px;
  min-width: 1000px;
  height: 100%;
  overflow: hidden;
}

/** Crew shifts module **/
.crew-shifts-module {
  display: grid;
  grid-template-rows: auto 1fr;
  font-size: 14px;
  color: var(--light-font-color);
  background-color: var(--darker-bg-color);
  border-radius: 12px;
  overflow-x: hidden;
  overflow-y: auto;

  margin-bottom: 24px;
  align-items: start;
}

.crew-shifts-module .crew-shifts-module-rows {
  display: grid;
  align-items: start;
}
.crew-shifts-module .crew-shifts-module-header,
.crew-shifts-module .crew-shifts-module-row {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr;
}
.crew-shifts-module .crew-shifts-module-header {
  position: sticky;
  top: 0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  z-index: 3;
}
.crew-shifts-module .crew-shifts-module-row {
  border-top: 1px solid var(--dark-bg-color);
  border-bottom: 1px solid var(--outline-darker-color);
}
.crew-shifts-module .crew-shifts-module-header {
  display: grid;
  font-size: 14px;
  font-weight: 600;
  color: var(--light-font-color);
  background-color: var(--dark-bg-color);
}
.crew-shifts-module .crew-shifts-module-header > div {
  display: grid;
  margin: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  align-items: center;
}
.crew-shifts-module .crew-shifts-module-header .actions {
  text-align: right;
}

.crew-shifts-module .crew-shifts-module-row {
  display: grid;
  background-color: var(--darker-bg-color);
  font-size: 14px;
  align-items: center;
  transition: opacity 0.25s ease-out;
}

.crew-shifts-module .crew-shifts-module-row.read-only {
  opacity: 0.5;
}

.crew-shifts-module .departments-input {
  margin-right: 32px;
}
.crew-shifts-module .crew-shifts-module-row.selected,
.crew-shifts-module .crew-shifts-module-row:not(.read-only):not(.slot):hover {
  background-color: var(--intermediate-bg-color);
  transition: background-color 0.25s ease;
}
.crew-shifts-module .crew-shifts-module-row > div {
  color: var(--base-font-color);
  display: inline-flex;
  margin: 10px 16px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.crew-shifts-module .crew-shifts-module-row .actions {
  display: inline-flex;
  gap: 8px;
  justify-content: flex-end;
}
.crew-shifts-module .crew-shifts-module-row .actions button {
  padding: 8px 10px;
  font-size: 14px;
}
.crew-shifts-module .crew-shifts-module-row .departments-input {
  margin-right: 24px;
}

.crew-shifts-module .crew-shifts-module-row .slot-placeholder {
  display: grid;
  grid-column: span 4;
  align-items: center;
  border: 1px dashed transparent;
  border-radius: 8px;
}
.crew-shifts-module .crew-shifts-module-row .slot-placeholder {
  border: 1px dashed var(--outline-color);
}

.crew-shifts-module .crew-shifts-module-row .slot-placeholder > div {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  margin: 8px;
}
.crew-shifts-module .crew-shifts-module-row .slot-placeholder button {
  padding: 8px 10px;
  font-size: 14px;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 4px;
}
.crew-shifts-module .crew-shifts-module-row .slot-placeholder button svg {
  width: 12px;
  height: 12px;
}
.crew-shifts-module .crew-shifts-module-row.slot .select-crew {
  display: grid;
  gap: 16px;
}
.crew-shifts-module .crew-shifts-date {
  display: grid;
}
.crew-shifts-module .crew-shifts-date > div:last-child {
  font-size: 10px;
  font-weight: 500;
  color: var(--light-font-color);
}

/** CrewShiftsOutlook **/
.crew-shifts-outlook {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  margin: 8px;
  cursor: default;
  gap: 6px;
}
.crew-shifts-outlook > div {
  display: grid;
  font-size: 12px;
  font-weight: 500;
  color: var(--dark-font-color);
  border-radius: 50%;
  background-color: transparent;
  text-align: center;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  align-items: center;
}
.crew-shifts-outlook > div.on-shift {
  background-color: var(--light-bg-color);
  color: var(--light-font-color);
}

/** Outlook detail **/
.crew-shifts-outlook-detail .crew-shift-rows {
  display: grid;
  color: var(--base-font-color);
  font-size: 14px;
  font-weight: 500;
  display: grid;
  align-content: start;
}

.crew-shifts-outlook-detail .crew-shift-row {
  display: grid;
}
.crew-shifts-outlook-detail .crew-shift-row > div {
  display: grid;
  gap: 16px;
  grid-template-columns: 48px 1fr;
  align-items: center;
  margin: 8px;
}
.crew-shifts-outlook-detail .crew-shift-row.today {
  background-color: var(--primary-blue-semi);
  border-radius: 8px;
}

.crew-shifts-outlook-detail .crew-shift-row .crew-shift-date {
  display: grid;
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  color: var(--lighter-font-color);
}

.crew-shifts-outlook-detail .crew-shift-row .crew-shift-date span {
  color: var(--medium-font-color);
  font-size: 8px;
  font-weight: 500;
}

.crew-shifts-outlook-detail .crew-shift-row .crew-shift-infos {
  display: grid;
  gap: 2px;
}
.crew-shifts-outlook-detail .crew-shift-row .crew-shift-info {
  display: grid;
  grid-template-columns: 1fr 96px;
  gap: 16px;
  align-items: center;
}

.crew-shifts-outlook-detail .crew-shift-row .crew-shift-time-range {
  display: grid;
  grid-template-columns: 36px auto 36px;
  font-size: 12px;
  font-weight: 600;
}

.crew-shifts-outlook-detail .crew-shift-row .crew-shift-time-range > div {
  display: grid;
  text-wrap: nowrap;
  align-items: center;
  text-align: right;
}
.crew-shifts-outlook-detail
  .crew-shift-row
  .crew-shift-time-range
  > div:last-child {
  text-align: left;
}

.crew-shifts-outlook-detail .crew-shift-row .crew-shift-department {
  font-size: 10px;
  font-weight: 500;
  color: var(--medium-font-color);
}

.crew-shift-preview h2 {
  font-size: 18px;
}
.crew-shift-preview span {
  font-size: 12px;
  font-weight: 500;
  color: var(--medium-font-color);
}

.crew-shift-preview .crew-shift-preview-content {
  display: grid;
  color: var(--base-font-color);
  margin: 0;
  width: 100%;
}

.crew-shift-preview .crew-shift-preview-rows {
  display: grid;
  align-items: center;
  align-content: start;

  font-size: 12px;
  font-weight: 500;
}
.crew-shift-preview .crew-shift-preview-row.header {
  font-weight: 600;
  background-color: var(--darkest-bg-color);
}
.crew-shift-preview .crew-shift-preview-cell {
  display: grid;
  align-items: center;
  margin: 8px 0 8px 16px;
  width: 80px;
  gap: 4px;
  align-content: start;
}
.crew-shift-preview .crew-shift-preview-cell > div {
  display: grid;
}
.crew-shift-preview .crew-shift-preview-cell > div:not(:first-child) {
  border-top: 1px solid var(--outline-darker-color);
  padding-top: 4px;
}

.crew-shift-preview .crew-shift-preview-row {
  display: grid;
  gap: 8px;
  border-bottom: 1px solid var(--outline-darker-color);
}
.crew-shift-preview .crew-shift-preview-row:not(.header):hover {
  background-color: var(--dark-bg-color);
  transition: all 0.25s ease-out;
}
.crew-shift-preview .crew-shift-preview-cell {
  display: grid;
  align-items: center;
  margin: 8px 0 8px 16px;
  width: 80px;
}
.crew-shift-preview .crew-shift-preview-cell:first-child {
  font-weight: 600;
}
.crew-shift-preview .crew-shift-preview-cell .crew-shift-preview-shift-detail {
  font-size: 9px;
  font-weight: 500;
  color: var(--medium-font-color);
}
.crew-shift-preview .crew-shift-preview-shift {
  display: grid;
  align-items: center;
}

.crew-shift-preview .crew-shift-preview-day {
  display: grid;
  align-items: center;
  font-size: 10px;
}
.crew-shift-preview .crew-shift-preview-day span {
  font-size: 9px;
}

.crew-shift-preview .old-shift {
  color: var(--primary-orange);
}
.crew-shift-preview .old-shift span {
  text-decoration: line-through;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}

.crew-shift-export .crew-shift-export-content {
  display: grid;
  margin: 16px;
  align-content: start;
  gap: 16px;
}
.crew-shift-export .crew-shift-export-content span {
  color: var(--base-font-color);
  font-size: 14px;
  font-weight: 500;
}

.crew-shift-preview .crew-shift-preview-shift.deleted-shift {
  color: var(--primary-orange);
  text-decoration: line-through;
}

