* {
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--base-bg-color);
  overflow: hidden;
}

.App {
  color: var(--base-font-color);
  height: 100vh;
}

hr {
  border: none;
  color: var(--outline-color);
  background-color: var(--outline-color);
  height: 1px;
}

/* Headers */
h2 {
  font-size: 32px;
  font-weight: 600;
  text-wrap: nowrap;
}
h2.badged {
  display: inline-flex;
  align-items: center;
}
h3 {
  font-size: 24px;
  font-weight: 600;
  text-wrap: nowrap;
}
h3.badged {
  display: inline-flex;
  align-items: center;
}
h4 {
  font-size: 18px;
  font-weight: 500;
  text-wrap: nowrap;
}

/* Form elements */
a {
  color: var(--light-font-color);
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

label {
  color: var(--light-font-color);
  font-size: 14px;
  font-weight: 500;
  cursor: inherit;
}

select,
input {
  background-color: var(--input-bg-color) !important;
  color: var(--input-font-color) !important;
  padding: 8px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid var(--outline-color);
  outline: none;
  font-family: Inter;
}

input::placeholder {
  color: var(--placeholder-font-color);
}
select.not-selected {
  color: var(--dark-font-color) !important;
  font-style: italic;
}
select:disabled,
input:disabled {
  cursor: not-allowed;
  opacity: 0.35;
}

input[type="checkbox"] {
  accent-color: var(--placeholder) !important;
}

/** button icons **/
.button-icon {
  cursor: pointer;
  opacity: 0.75;
}
.button-icon:hover {
  opacity: 1;
}

/** basic buttons **/
button {
  box-sizing: border-box;
  padding: 10px 14px;
  color: var(--base-font-color);
  background-color: var(--primary-blue);
  border: 1px solid transparent;
  border-radius: 8px;
  font-family: "Inter", "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-wrap: nowrap;
  cursor: pointer;
  outline: none;
  opacity: 0.7;
  transition: all 0.25s ease;
}

button svg {
  vertical-align: bottom;
  margin-right: 2px;
  width: 18px;
  height: 18px;
}

button:not([disabled]):hover {
  opacity: 1;
}

button:not([disabled]).menu:hover,
button:not([disabled]).secondary:hover {
  opacity: 1;
  background-color: var(--darker-bg-color);
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

button.secondary {
  background-color: transparent;
  border: 1px solid var(--outline-color);
  color: var(--light-font-color);
}

button.semiblue {
  padding: 10px 12px;
  background-color: var(--primary-blue-semi);
  color: var(--primary-blue);
  text-transform: uppercase;
  font-size: 12px;
}

button.outlined {
  background-color: transparent;
  border: 1px solid var(--outline-light-color);
  color: var(--base-font-color);
  opacity: 0.7;
}
button:not([disabled]).outlined:hover {
  opacity: 1;
}

button.outline-filled {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4px;
  font-size: 12px;
  background-color: var(--medium-bg-color);
  border: 1px solid var(--outline-color);
  color: var(--base-font-color);
  padding: 6px 8px;
  align-items: center;
}

button.alternate {
  background-color: var(--gray-dimmed-color);
  border: 1px solid transparent;
  color: var(--base-font-color);
  opacity: 0.5;
}
button.alternate:hover {
  opacity: 1;
}

button.menu {
  background-color: transparent;
  border: 1px solid var(--outline-color);
}
button.menu.open {
  background-color: var(--darker-bg-color);
  border: 1px solid var(--outline-color);
}

button.danger {
  background-color: var(--primary-red);
  border: 0;
  color: var(--base-font-color);
  border: 1px solid var(--primary-red);
}
button.danger-outline {
  border: 1px solid var(--primary-red);
  color: var(--primary-red);
  background-color: transparent;
  opacity: 0.7;
}
button.danger-outline:hover {
  border: 1px solid var(--primary-red);
  color: var(--primary-red);
  background-color: transparent;
  opacity: 1;
}
button.green {
  background-color: var(--primary-green);
  border: 0;
  color: var(--base-font-color);
}
button.white {
  background-color: var(--pure-white);
  border: 0;
  color: var(--dark-font-color);
}
button.white {
  background-color: var(--pure-white);
  border: 0;
  color: var(--dark-font-color);
}
button.rounded-white {
  background-color: var(--pure-white);
  border: 0;
  color: var(--dark-font-color);
  border-radius: 18px;
}
button.danger svg path {
  fill: var(--base-font-color);
}

button.nav-left,
button.nav-right {
  background-color: var(--light-bg-color);
  width: 30px;
  display: grid;
  padding: 0;
  justify-items: center;
  height: 32px;
  align-items: center;
}
button.nav-left svg {
  transform: rotate(90deg);
  margin: 0;
}

button.nav-right svg {
  transform: rotate(-90deg);
}

.empty-state {
  padding: 16px;
  margin: 32px auto;
  border-radius: 12px;
  text-align: center;
}

.de-emphasize {
  font-style: italic;
  color: var(--light-font-color);
}

.divider {
  border-left: 1px solid var(--divider-color);
  width: 1px;
  height: 100%;
}

.capitalized {
  text-transform: capitalize;
}

.all-caps {
  text-transform: uppercase;
}

/** Modal **/
.modal {
  height: 80vh;
  min-width: 600px;
  border-radius: 12px;
  background-color: var(--darker-bg-color);
  border: 3px solid var(--outline-medium-color);
  display: grid;
  grid-template-rows: auto 1fr auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.modal.modal-small {
  height: 30vh;
}
.modal.modal-50 {
  height: 50vh;
}
.modal.modal-medium {
  height: 60vh;
}

.modal:focus-visible {
  outline: none !important;
}

.modal .modal-body {
  height: 100%;
  overflow-y: auto;
  display: grid;
  scrollbar-color: var(--scrollbars);
}

.modal .modal-header,
.modal .modal-body-container,
.modal .modal-footer {
  margin: 16px;
  color: var(--base-font-color);
}
.modal .modal-header,
.modal .modal-footer {
  display: grid;
  grid-template-columns: 1fr auto;
}
.modal h2 {
  font-size: 18px;
  font-weight: 600;
}
.modal .button-icon {
  margin: 8px;
  cursor: pointer;
}

.modal .modal-container {
  display: grid;
  gap: 16px;
  height: 100%;
  /* overflow-y: auto; */
}
.modal .modal-field-row {
  margin: 0 24px;
  display: grid;
  gap: 8px;
}
span {
  cursor: inherit;
}
span.danger {
  color: var(--primary-red);
}
svg.danger path {
  fill: var(--primary-red);
}
span.affirmative {
  color: var(--primary-green);
}
span.in-progress {
  color: var(--primary-yellow);
}

.drop-shadow {
  filter: drop-shadow(2px 4px 6px var(--drop-shadow-color));
}

.not-visible {
  display: none !important;
}
.hideout {
  width: 0;
  height: 0;
  overflow: hidden;
}
.temp-hidden {
  opacity: 0;
}

.ellipsis-text {
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.centered-text {
  text-align: center;
}

/* Animation */
.fadeIn {
  animation-duration: 0.25s;
  animation-name: fadeInAnimation;
  animation-timing-function: ease-in;
}
@keyframes fadeInAnimation {
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
}

.fadeInLoop {
  animation-duration: 2s;
  animation-name: fadeLoopAnimation;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes fadeLoopAnimation {
  from {
    opacity: 0%;
  }
  50% {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
}

.pulseBg {
  animation-duration: 2s;
  animation-name: bgLoopAnimation;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes bgLoopAnimation {
  from {
    background-color: var(--primary-blue-semi);
  }
  50% {
    background-color: var(--primary-blue);
  }
  to {
    background-color: var(--primary-blue-semi);
  }
}

.pulseOpacity {
  animation-duration: 2s;
  animation-name: opacityLoopAnimation;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}
@keyframes opacityLoopAnimation {
  from {
    opacity: 0.25;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0.25;
  }
}

/** Listing **/
.listing {
  cursor: default;
}
.listing .listing-header {
  display: grid;
  font-size: 14px;
  font-weight: 600;
  color: var(--light-font-color);
  background-color: var(--darker-bg-color);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.listing .listing-row {
  display: grid;
  font-size: 14px;
  align-items: center;
}

.listing .listing-empty {
  display: grid;
  background-color: var(--dark-bg-color);
  text-align: center;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  color: var(--light-font-color);
  font-style: italic;
}
.listing .listing-empty > div {
  margin: 32px;
}
.listing .listing-header > div,
.listing .listing-row > div {
  display: grid;
  margin: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.listing .listing-header > div:first-child,
.listing .listing-row > div:first-child {
  margin-left: 24px;
}
.listing .listing-row:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}
.listing .listing-row {
  background-color: var(--dark-bg-color);
}
.listing .listing-row:hover {
  background-color: var(--intermediate-bg-color);
  transition: background-color 0.25s ease;
}
.listing .actions {
  display: grid;
  text-align: right;
}
.listing button {
  padding: 8px 10px;
}
.listing button svg {
  margin-right: 4px;
}
.listing .critical-messages {
  display: grid;
  gap: 4px;
}
