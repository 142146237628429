.dispatches {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  overflow: hidden;
}
.dispatches .page-toolbar {
  align-items: center;
}

.dispatches .dispatches-header-row {
  display: grid;
  align-items: center;
  margin: 0 32px;
}
.dispatches .dispatches-header-row > div {
  display: grid;
  align-items: center;
}
.dispatches .dispatches-container {
  display: grid;
  gap: 16px;
  overflow: auto;
  grid-template-rows: auto 1fr auto;
}
.dispatches .dispatches-container.listing {
  grid-template-rows: auto auto auto 1fr;
}
.dispatches .dispatches-content {
  display: grid;
  margin: 0 32px;
  min-width: 900px;
}
.dispatches .dispatches-content:last-child {
  margin-bottom: 32px;
}
.dispatches h3 {
  margin: 16px 0;
}
.dispatches h4 {
  margin: 8px 0;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto 1fr;
  gap: 8px;
}
.dispatches .dispatch-info {
  display: grid;
  gap: 8px;
  margin: 0 auto;
}
.dispatches .dispatch-info {
  display: inline-grid;
  gap: 8px;
  min-width: 365px;
}
.dispatches .dispatch-info-row {
  display: grid;
  grid-template-columns: auto repeat(4, minmax(0, 1fr));
  gap: 12px;
  align-items: center;
  font-size: 12px;
  text-wrap: nowrap;
}
.dispatches .dispatch-info-row svg {
  margin: 0 8px;
}

.dispatches .dispatch-info-row .outline {
  display: grid;
  border: 1px solid var(--outline-color);
  border-radius: 8px;
  background-color: var(--translucent);
  height: 100%;
}
.dispatches .dispatch-info-row .label-value-pair {
  display: grid;
  grid-template-rows: 1fr;
  gap: 4px;
  margin: 4px 6px;
}
.dispatches .dispatch-info-row .label-value-pair label {
  font-size: 10px;
  text-transform: uppercase;
  text-wrap: wrap;
}
/** Listing overrides **/
.dispatches-content.active .listing-header,
.dispatches-content.active .listing-row {
  grid-template-columns: repeat(4, minmax(0, 1fr)) 140px;
}
.dispatches-content.upcoming .listing-header,
.dispatches-content.upcoming .listing-row {
  grid-template-columns: repeat(3, minmax(0, 1fr)) 140px;
}
.dispatches-content.active .listing-empty {
  grid-column: 5;
}
.dispatches-content.upcoming .listing-empty {
  grid-column: 5;
}

/** Dispatch editor **/
.dispatch-editor {
  display: grid;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  grid-template-rows: auto 1fr;
}
.dispatch-editor .dispatch-editor-container {
  display: grid;
  overflow-y: auto;
}
.dispatch-editor .dispatch-editor-header-content {
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: center;
  margin: 24px;
  gap: 16px;
  text-wrap: nowrap;
}
.dispatch-editor .dispatch-editor-header-content .dispatch-editor-header-info {
  display: grid;
  gap: 8px;
}
.dispatch-editor .dispatch-editor-header-content > div {
  display: grid;
}

.dispatch-editor .dispatch-editor-content {
  display: grid;
  gap: 24px;
  margin-bottom: 24px;
}
.dispatch-editor .dispatch-editor-module {
  display: grid;
  margin: 0 24px;
  background-color: var(--darker-bg-color);
  border-radius: 16px;
  transition-property: background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
}
.dispatch-editor .dispatch-editor-module.disabled {
  background-color: var(--disabled-bg-color);
  transition-property: background-color;
  transition-duration: 0.25s;
  transition-timing-function: ease-out;
  color: var(--light-font-color);
}
.dispatch-editor .dispatch-editor-header-content > div:last-child {
  justify-content: right;
  display: grid;
}
.dispatch-editor .dispatch-editor-header span {
  color: var(--light-font-color);
  font-size: 16px;
}
.dispatch-editor .dispatch-editor-header .actions {
  display: inline-grid;

  gap: 16px;
  min-width: 240px;
}
.dispatch-editor .dispatch-editor-header .dispatch-editor-module {
  margin-bottom: 24px;
}

.dispatch-editor h4 {
  margin: 0 0 4px 0;
  font-weight: 500;
}

.dispatch-editor .dispatch-editor-module-header {
  display: grid;
  margin: 24px;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.dispatch-editor .dispatch-editor-module-header .title {
  display: inline-grid;
  grid-template-columns: auto auto 1fr;
  align-items: center;
}

.dispatch-editor .dispatch-editor-module-inset {
  display: grid;
  margin: 0 24px 24px 24px;
  background-color: var(--semi-transparent);
  border-radius: 12px;
}
.dispatch-editor .dispatch-editor-module-inset > div {
  margin: 16px;
}

.dispatch-editor .dispatch-editor-module-row {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.dispatch-editor .dispatch-editor-module-title {
  color: var(--light-font-color);
  font-size: 16px;
  font-weight: 500;
}

.dispatch-editor .dispatch-editor-module-body {
  display: grid;
  grid-template-columns: 1fr 320px;
}
.dispatch-editor .dispatch-editor-module-assignment {
  display: grid;
}
.dispatch-editor .dispatch-editor-module-assignment > div {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.dispatch-editor .dispatch-editor-module-assignment.empty-slot > div {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 16px;
  margin: 12px;
}
.dispatch-editor .dispatch-editor-module-assignment .user-badge {
  margin: 0;
}

.dispatch-editor .dispatch-editor-module-assignment.empty-slot {
  font-size: 14px;
  border: 1px dashed var(--primary-red);
  border-radius: 8px;
}

.dispatch-editor .dispatch-editor-module-assignment.empty-slot .unassigned {
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-red);
}
.dispatch-editor .dispatch-editor-module-assignment.empty-slot span {
  font-size: 12px;
  color: var(--light-font-color);
}

.dispatch-editor button {
  padding: 8px 10px;
}

.dispatch-editor .empty-slot button {
  padding: 6px 12px;
}

.dispatch-editor .dispatch-editor-module-side > div {
  display: grid;
  gap: 16px;
  margin: 0 16px 16px 0;
}

.dispatch-editor .label-value-pair {
  display: grid;
  gap: 8px;
  font-size: 12px;
  font-weight: 600;
}
.dispatch-editor .label-value-pair .stat {
  border: 1px solid var(--outline-color);
  border-radius: 8px;
}
.dispatch-editor .label-value-pair.danger {
  color: var(--primary-red);
}
.dispatch-editor .label-value-pair.danger .stat {
  border: 1px solid var(--primary-red);
}
.dispatch-editor .label-value-pair .stat > div {
  margin: 8px;
  font-size: 14px;
}

.dispatch-editor .field-pair {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 2fr;
}

/** Select crew **/
.dispatch-editor .dispatch-editor-module-assignment.empty-slot .select-crew {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr;
  gap: 8px;
}

.select-crew {
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: hidden;
  gap: 8px;
}
.select-crew .select-crew-search {
  display: grid;
}
.select-crew .select-crew-results {
  display: grid;
  gap: 8px;
}
.select-crew .select-crew-result-row {
  border-radius: 8px;
  opacity: 0.7;
  transition: all 0.25s ease-out;
}
.select-crew .section-label {
  font-weight: 600;
  margin: 4px 0;
}
.select-crew .select-crew-result-row > div {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  margin: 2px 4px;
}
.select-crew .select-crew-result-row:hover {
  cursor: pointer;
  opacity: 1;
  background-color: var(--semi-transparent);
}

.select-crew .select-crew-results-container {
  display: grid;
  overflow-y: auto;
  overflow-x: hidden;
}

.select-crew .search-input {
  border: 1px solid var(--outline-color);
  background-color: var(--darker-bg-color);
  border-radius: 8px;
}
.select-crew .select-crew-container {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  margin: 2px 4px;
}
.select-crew .select-crew-container > div {
  display: grid;
  align-items: center;
}

.select-crew .search-input input {
  border: none;
  outline: none;
  background-color: var(--darker-bg-color) !important;
}
.select-crew .search-input svg {
  width: 24px;
}

/** Override Labels **/
.dispatch-editor .dispatch-overrides-list {
  display: flex;
  gap: 8px;
}

/** Badge **/
.dispatch-editor .badge {
  border: 1px solid var(--base-font-color);
  color: var(--base-font-color);
  border-radius: 6px;
  padding: 4px 8px;
  display: inline-grid;
  font-size: 10px;
  text-transform: uppercase;
  vertical-align: middle;
  margin-left: 16px;
}

/** Critical message **/
.critical-message {
  color: var(--primary-red);
  font-size: 14px;
  font-weight: 500;
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 4px;
}
.critical-message svg {
  vertical-align: bottom;
}

/** Template selector modal **/
.template-selector-modal {
  height: 240px;
  color: var(--base-font-color);
}
.template-selector-modal .template-selector-modal-header {
  display: grid;
  margin: 32px 32px 0 32px;
  gap: 8px;
}
.template-selector-modal .template-selector-modal-header h3 {
  font-size: 18px;
  font-weight: 600;
}
.template-selector-modal .template-selector-modal-header span {
  font-size: 14px;
  font-weight: 500;
  color: var(--light-font-color);
}
.template-selector-modal .template-selector-modal-body {
  margin: 32px;
}
.template-selector-modal .template-selector-modal-actions > div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
  margin: 0 32px 32px 32px;
}

/** turnaround-labels **/
.dispatch-overrides-editor {
  align-items: start;
  grid-template-rows: auto 1fr;
}
.dispatch-overrides-editor
  .dispatch-editor-module-side
  .turnaround-label-summary {
  display: grid;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
  color: var(--light-font-color);
}
.dispatch-overrides-editor
  .dispatch-editor-module-side
  .turnaround-label-summary.conflicts {
  color: var(--primary-red);
}

.dispatch-overrides-editor .turnaround-labels {
  display: grid;
  gap: 16px;
}
.dispatch-overrides-editor .turnaround-labels > div {
  display: grid;
  gap: 8px;
}

.dispatch-overrides-editor .turnaround-labels .turnaround-label-info {
  display: grid;
}

.dispatch-overrides-editor .turnaround-labels .turnaround-label-module .toggle {
  cursor: pointer;
}
.dispatch-overrides-editor
  .turnaround-labels
  .turnaround-label-module.opened
  .toggle {
  rotate: 180deg;
}
.dispatch-overrides-editor
  .turnaround-labels
  .turnaround-label-module
  .toggle-label {
  opacity: 0.7;
  cursor: pointer;
  transition: opacity 0.25s ease;
}
.dispatch-overrides-editor
  .turnaround-labels
  .turnaround-label-module.selected
  .toggle-label,
.dispatch-overrides-editor
  .turnaround-labels
  .turnaround-label-module
  .toggle-label:hover {
  opacity: 1;
}
.dispatch-overrides-editor .turnaround-labels .turnaround-label-info > div {
  display: grid;
  grid-template-columns: auto 1fr auto;
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  margin: 8px;
  gap: 8px;
}
.dispatch-overrides-editor
  .turnaround-labels
  .turnaround-label-module.opened
  .turnaround-label-overrides {
  display: grid;
  gap: 8px;
  margin-left: 16px;
  background-color: var(--semi-transparent);
  border-radius: 12px;
}
.dispatch-overrides-editor
  .turnaround-labels
  .turnaround-label-module
  .turnaround-label-overrides {
  display: none;
}
.dispatch-overrides-editor
  .turnaround-labels
  .turnaround-label-overrides
  > div {
  display: grid;
  gap: 16px;
  margin: 16px;
}
.dispatch-overrides-editor
  .turnaround-labels
  .turnaround-label-overrides
  .turnaround-label-operation-details {
  display: grid;
  gap: 16px;
  grid-template-columns: auto 1fr;
}

.dispatch-overrides-editor
  .turnaround-labels
  .turnaround-label-overrides
  .turnaround-label-operation-details-section {
  display: grid;
  gap: 16px;
}
.dispatch-overrides-editor
  .turnaround-labels
  .turnaround-label-overrides
  .turnaround-label-operation-details-section:first-child {
  margin-left: 12px;
}

.dispatch-overrides-editor .turnaround-labels .turnaround-label-operation-info {
  display: grid;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--light-font-color);
}

.dispatch-overrides-editor .turnaround-labels .label-value-row {
  display: grid;
  grid-template-columns: 180px 1fr;
  gap: 16px;
  align-items: center;
}
.dispatch-overrides-editor .turnaround-labels .label-value-row div:first-child {
  font-weight: 500;
  color: var(--base-font-color);
  font-size: 14px;
}
.dispatch-overrides-editor .turnaround-labels .label-value-row div:last-child {
  font-weight: 500;
  color: var(--light-font-color);
  font-size: 14px;
}

.dispatch-overrides-editor
  .turnaround-labels
  .turnaround-label-operation-requirements {
  display: grid;
  gap: 8px;
}

.dispatch-editor .dispatch-overrides {
  display: grid;
}
.dispatch-editor .dispatch-overrides button {
  font-size: 12px;
  padding: 6px 8px;
}
.dispatch-editor .dispatch-overrides .dispatch-overrides-list > div {
  display: grid;
  align-items: center;
  gap: 8px;
}

.dispatch-editor .dispatch-overrides-empty {
  display: grid;
  align-items: center;
}
