.playback-tool {
  display: grid;
  grid-template-rows: 1fr;
  height: 100%;
  position: relative;
}
.playback-tool .playback-tool-content {
  position: relative;
  background-color: transparent;
}
.playback-tool .playback-tool-content .loading-state {
  position: absolute;
  z-index: 10;
  width: 100%;
}
.playback-tool .playback-tool-map {
  width: 100%;
  height: 100%;
}

.playback-tool.loading .playback-tool-map {
  opacity: 0.5;
}

.playback-tool .playback-tool-bottom-panel {
  background-color: var(--light-bg-color);
}

.playback-tool .playback-tool-controls {
  display: grid;
  position: absolute;
  width: 720px;
  height: 75px;
  background-color: var(--pure-black-transparent);
  align-items: center;
  bottom: 64px;
  left: 50%;
  transform: translate(-50%);
  border: 1px solid var(--outline-color);
  border-radius: 18px;
}
.playback-tool .playback-tool-controls > div {
  display: grid;
  margin: 18px;
  align-items: center;
}
.playback-tool .playback-tool-controls-content {
  display: grid;
  grid-template-columns: auto auto 1fr 64px;
  gap: 18px;
  align-items: center;
}

.playback-tool .playback-rate select {
  font-size: 12px;
  font-weight: 600;
}
.playback-tool .playback-tool-display-time {
  display: grid;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}
/** Playback button **/
.playback-buttons {
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  gap: 4px;
  align-items: center;
}
.playback-button {
  display: grid;
  background-color: var(--primary-blue);
  opacity: 0.75;
  transition: all 0.25s ease-out;
  align-items: center;
}
.playback-button:hover:not(:disabled) {
  opacity: 1;
}
.playback-button.playing {
  opacity: 1;
}

.playback-tool-timeline {
  display: grid;
  width: 100%;
  height: 32px;
}
.playback-buttons button.primary {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0 0 0 0;
}
.playback-buttons button.primary svg {
  vertical-align: middle;
  margin: 0 auto;
}
.playback-buttons button.secondary {
  padding: 6px;
}
.playback-buttons button.secondary svg {
  width: 12px;
  height: 12px;
  vertical-align: middle;
  margin: 0 auto;
}

/** Playback timeline **/
.playback-timeline {
  display: grid;
  position: relative;
}
.playback-timeline-container {
  display: grid;
  width: 100%;
  height: 100%;
  background-color: var(--medium-bg-color);
  border: 1px solid var(--outline-color);
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  cursor: grab;
}
.playback-timeline .dragging {
  cursor: grabbing;
}
.playback-timeline-playhead {
  position: absolute;
  left: 50%;
}
.playback-timeline-playhead > div {
  position: relative;
  height: 32px;
  width: 0px;
  transform: translate(1px);
  border-left: 1px solid var(--pure-white-dim);
  border-right: 1px solid var(--pure-white-dim);
  pointer-events: none;
}
.playback-timeline.dragging .playback-timeline-playhead > div {
  border: none;
}

.playback-timeline-playhead svg {
  position: absolute;
  left: 50%;
  top: -8px;
  transform: translate(-50%);
  pointer-events: none;
}
.playback-timeline.dragging .playback-timeline-playhead svg {
  left: calc(50% + 1px);
}
.playback-timeline-slider {
  display: grid;
  align-items: center;
  font-size: 10px;
  font-weight: 600;
  color: var(--light-font-color);
  text-transform: uppercase;
  position: absolute;
  top: 4px;
}
.playback-timeline-slider-bucket {
  display: grid;
  gap: 2px;
}
.playback-timeline-slider-tick {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-left: 1px solid var(--outline-light-color);
  border-top: 1px solid var(--outline-light-color);
  pointer-events: none;
  user-select: none;
}
.playback-timeline-slider-subtick {
  height: 8px;
  border-left: 1px solid var(--outline-light-color);
  pointer-events: none;
  user-select: none;
}
.playback-timeline-slider-label {
  display: grid;
  position: relative;
  pointer-events: none;
  user-select: none;
}
.playback-timeline-slider-label > div {
  position: absolute;
  transform: translate(-50%);
  pointer-events: none;
  user-select: none;
}

/** Mapbox overrride **/
.mapboxgl-ctrl .mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib-inner {
  display: none;
}

/** Time selector modal **/
.playback-time-selector-modal .playback-time-selector {
  display: grid;
  margin: 0 16px;
  align-items: start;
}
.playback-time-selector-modal .playback-time-selector-row {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.playback-time-selector-modal .playback-time-selector-row label {
  font-size: 14px;
  font-weight: 600;
}
.playback-time-selector-modal .time-input {
  padding: 4px;
}
