.turnarounds-module {
  display: grid;
  grid-template-rows: auto 1fr;
  height: 100%;
  overflow: auto;
  gap: 16px;
}

.turnarounds-module .list-container {
  margin: 0 24px 24px 24px;
}
.turnarounds-module .list-row > div,
.turnarounds-module .list-header > div {
  margin: 12px 4px;
}
.turnarounds-module .list-row > div:first-child,
.turnarounds-module .list-header > div:first-child {
  margin: 12px 12px 12px 16px;
}
.turnarounds-module .list-row {
  border-bottom: 1px solid var(--outline-darker-color);
}

.turnarounds-module .turnarounds-module-container {
  display: grid;
  overflow: auto;
}
.turnarounds-module .turnarounds-module-container.tasks {
  display: grid;
  overflow: auto;
  grid-template-rows: auto 1fr;
  min-width: 1200px;
  margin: 0;
}

.turnarounds-module
  .turnarounds-module-container.tasks
  .turnarounds-module-content {
  display: grid;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid var(--outline-color);
  position: relative;
  margin-bottom: 24px;
}

.turnarounds-module .turnarounds-module-header-sections {
  display: grid;
  gap: 16px;
  margin: 24px 24px 0 24px;
  grid-template-columns: 1fr auto auto;
  align-items: center;
}

.turnarounds-module .turnarounds-module-header .titles span {
  color: var(--medium-font-color);
  font-size: 14px;
  font-weight: 500;
}

.turnarounds-module .turnarounds-module-header .actions > div {
  display: flex;
  gap: 8px;
  align-items: center;
}

.turnarounds-module .turnarounds-module-header .actions button svg {
  margin-right: 4px;
}

.turnarounds-module-tasks {
  display: grid;
  grid-template-columns: 360px 1fr;
}

.turnarounds-module-tasks .turnarounds-module-tasks-sidepanel > div {
  display: grid;
  font-size: 12px;
  font-weight: 500;
}
.turnarounds-module-tasks .turnarounds-module-tasks-container {
  display: grid;
  height: 100%;
  position: relative;
}
.turnarounds-module-tasks .turnarounds-module-tasks-container .gridlines {
  display: grid;
  height: 100%;
}
.turnarounds-module-tasks .turnarounds-module-tasks-container .timelinebars {
  display: grid;
  height: 100%;
  position: absolute;
}
.turnarounds-module-tasks .turnarounds-module-timelinebar {
  display: grid;
  height: 24px;
  background-color: var(--primary-blue);
  border-radius: 6px;
  position: absolute;
  align-items: center;
  cursor: default;
  opacity: 0.75;
  transition: all 0.25s ease-out;
}
.turnarounds-module-tasks .turnarounds-module-timelinebar.expected {
  background-color: var(--gray-dimmed-color);
  border: 1px solid var(--gray-light-color);
}
.turnarounds-module-tasks .turnarounds-module-timelinebar.point {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid var(--outline-medium-color);
}
.turnarounds-module-tasks .turnarounds-module-timelinebar.gse {
  background-color: var(--primary-purple);
}
.turnarounds-module-tasks .turnarounds-module-timelinebar.user {
  background-color: var(--primary-orange);
}
.turnarounds-module-tasks .turnarounds-module-timelinebar.hovered {
  opacity: 1;
}
.turnarounds-module-tasks .turnarounds-module-timelinebar.child-task {
  background-color: var(--light-bg-color);
}
.turnarounds-module-tasks .turnarounds-module-timelinebar > div {
  display: grid;
  position: relative;
  align-items: center;
}
.turnarounds-module-tasks .turnarounds-module-timelinebar-label {
  display: grid;
  position: absolute;
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
  text-wrap: nowrap;
  opacity: 0.5;
}
.turnarounds-module-tasks
  .turnarounds-module-timelinebar.hovered
  .turnarounds-module-timelinebar-label {
  opacity: 1;
}
.turnarounds-module-tasks
  .turnarounds-module-tasks-container
  .timelinebars
  > div {
  display: grid;
  align-items: center;
}

.turnarounds-module-tasks .turnarounds-module-task {
  display: grid;
  transition: all 0.25s ease-out;
}
.turnarounds-module-tasks .turnarounds-module-task.hovered {
  background-color: var(--darker-bg-color);
}

.turnarounds-module-tasks .turnarounds-module-row {
  display: grid;
  grid-template-columns: 220px 60px 60px;
  align-items: center;
  cursor: default;
  font-size: 12px;
  font-weight: 500;
  color: var(--light-font-color);
  transition: all 0.25s ease-out;
  min-height: 45px;
  gap: 8px;
}
.turnarounds-module-tasks .turnarounds-module-row.hovered {
  background-color: var(--darker-bg-color);
  color: var(--base-font-color);
}

.turnarounds-module-tasks .task-label {
  display: grid;
  font-size: 14px;
  font-weight: 600;
  color: var(--base-font-color);
  margin: 8px;
  align-items: center;
  cursor: default;
}
.turnarounds-module-tasks .task-label > div {
  margin-left: 8px;
}

.turnarounds-module-tasks .task-label svg {
  width: 18px;
  height: 18px;
  rotate: -90deg;
  vertical-align: middle;
}
.turnarounds-module-tasks .expandable {
  cursor: pointer;
}
.turnarounds-module-tasks .task-label.expanded svg {
  rotate: 0deg;
}

.turnarounds-module-tasks .task-detail {
  font-size: 12px;
  font-weight: 500;
  color: var(--light-font-color);
}

.turnarounds-module-tasks .child-task {
  color: var(--light-font-color);
  font-weight: 500;
}

.turnarounds-module-tasks .value-cell {
  display: grid;
  font-size: 12px;
  font-weight: 500;
}

.turnarounds-module-tasks .turnarounds-module-tasks-sidepanel,
.turnarounds-module-tasks .turnarounds-module-task > div {
  display: grid;
  border-right: 1px solid var(--outline-darker-color);
}

/** Timeline marker **/
.turnarounds-module .timeline-marker {
  display: grid;
  position: absolute;
  width: 0px;
  height: 100%;
  transition: opacity 0.25s ease-out;
  left: 360px;
}

.turnarounds-module .timeline-marker.point-marker {
  pointer-events: none;
  border-left: none;
}

.turnarounds-module .timeline-marker.point-marker .timeline-marker-line,
.turnarounds-module .timeline-marker.point-marker:hover .timeline-marker-line {
  border-left: 1px dashed var(--primary-blue-semi);
  border-right: 1px dashed var(--primary-blue-semi);
}
.turnarounds-module .timeline-marker .timeline-marker-line {
  border-left: 1px dashed var(--pure-white-tint);
  border-right: 1px dashed var(--pure-white-tint);
  transition: all 0.25s ease-out;
  margin-left: -1px;
}

.turnarounds-module .timeline-marker:hover .timeline-marker-line {
  border-left: 1px dashed var(--pure-white-dim);
  border-right: 1px dashed var(--pure-white-dim);
}
.turnarounds-module .timeline-marker > div {
  display: grid;
  grid-template-rows: 42px 1fr;
  position: relative;
}
.turnarounds-module .timeline-marker-label-anchor {
  display: grid;
  height: 42px;
  position: relative;
}
.turnarounds-module .timeline-marker-label {
  display: grid;
  position: absolute;
  /* background-color: var(--overlay-gray-color); */
  background-color: var(--medium-bg-color);
  border-radius: 4px;
  transform: translate(-50%, calc(-50% - 2px));
  top: 50%;
  text-align: center;
  cursor: default;
}

.turnarounds-module .timeline-marker.point-marker .timeline-marker-label {
  background-color: var(--primary-blue);
}

.turnarounds-module .timeline-marker-label > div {
  display: grid;
  margin: 4px 6px;
  text-transform: uppercase;
  text-wrap: nowrap;
}
.turnarounds-module .timeline-marker-label-title {
  font-size: 9px;
  font-weight: 500;
  color: var(--light-font-color);
}
.turnarounds-module .timeline-marker-label-value {
  font-size: 12px;
  font-weight: 600;
  color: var(--base-font-color);
}

/** HEADER **/
.turnarounds-module-tasks-header {
  display: grid;
  grid-template-columns: 360px 1fr;
  position: sticky;
  top: 0;
  z-index: 2;
  border-bottom: 1px solid var(--outline-darker-color);
  background-color: var(--darker-bg-color);
  text-align: center;
}
.turnarounds-module-tasks-header > div:first-child {
  display: grid;
  grid-template-columns: 220px 60px 60px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  gap: 8px;
}
.turnarounds-module-tasks-header > div {
  display: grid;
  border-right: 1px solid var(--outline-darker-color);
}
.turnarounds-module-tasks-header .value-cell {
  display: grid;
  font-size: 14px;
  font-weight: 500;
  margin: 12px;
}
.turnarounds-module-tasks-header .value-cell.value-label {
  color: var(--medium-font-color);
  font-size: 12px;
  font-weight: 600;
  margin: 12px -100% 12px 0;
}

.turnarounds-module .task-details {
  display: grid;
  gap: 2px;
}
.turnarounds-module .task-details > div:first-child {
  font-size: 12px;
  color: var(--base-font-color);
}
.turnarounds-module .task-details > div:last-child {
  font-size: 10px;
  color: var(--light-font-color);
  text-transform: uppercase;
}
/** Markers **/
.turnarounds-module .turnarounds-module-markers {
  margin-left: 360px;
  position: absolute;
  z-index: 3;
  width: auto;
  height: 100%;
}

/** Override to table **/
.turnarounds-module .list-actions {
  text-align: left;
  gap: 12px;
  align-content: center;
}

.turnarounds-module .list-header-col.list-actions {
  display: none;
}

/** Config **/
.turnarounds-module-content {
  display: grid;
  margin: 0 24px;
}
.turnarounds-module-config {
  display: grid;
  grid-template-columns: 240px 1fr;
  align-items: start;
  gap: 16px;
}

.turnarounds-module-config .side-bar {
  display: grid;
  background-color: var(--darker-bg-color);
  border-radius: 12px;
}

.turnarounds-module-config .side-bar > div {
  display: grid;
  margin: 16px;
  gap: 8px;
}

.turnarounds-module-config .side-bar .side-bar-item {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  background-color: var(--medium-bg-color);
  border: 1px solid var(--outline-dark-color);
  border-radius: 12px;
  font-size: 14px;
  font-weight: 600;
  color: var(--dark-font-color);
  transition: all 0.25s ease-out;
  cursor: pointer;
  min-height: 48px;
}
.turnarounds-module-config .side-bar .side-bar-label {
  display: grid;
  font-size: 14px;
  font-weight: 600;
  margin: 0 8px;
}
.turnarounds-module-config .side-bar .side-bar-label > div {
  margin: 0 16px;
}
.turnarounds-module-config .side-bar .side-bar-item.enabled {
  color: var(--base-font-color);
  border: 1px solid var(--outline-medium-color);
}
.turnarounds-module-config .side-bar .side-bar-item.selected,
.turnarounds-module-config .side-bar .side-bar-item:hover {
  background-color: var(--primary-blue-semi);
  border: 1px solid var(--primary-blue-dim);
}
.turnarounds-module-config .side-bar .side-bar-item > div {
  display: grid;
  margin: 8px 12px;
  align-items: center;
  gap: 4px;
}
.turnarounds-module-config .side-bar .checkbox-container {
  display: grid;
  align-items: center;
}

.turnarounds-module-config .side-bar .completed {
  display: grid;
  font-size: 10px;
  font-weight: 500;
  color: var(--medium-font-color);
  text-transform: uppercase;
}
.turnarounds-module-config .side-bar .in-progress {
  display: grid;
  align-content: center;
  grid-template-columns: auto 1fr;
  gap: 6px;
  font-size: 10px;
  font-weight: 500;
  color: var(--primary-yellow);
  text-transform: uppercase;
}
.turnarounds-module-config .side-bar .in-progress > div {
  display: grid;
  align-content: center;
}
.turnarounds-module-config .side-bar .pulse {
  background-color: var(--base-font-color);
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.turnarounds-module-config .main-container {
  display: grid;
  grid-template-rows: auto auto 1fr;
  gap: 16px;
}

.turnarounds-module-config .module-section {
  display: grid;
  background-color: var(--darker-bg-color);
  border-radius: 12px;
  overflow: hidden;
  min-width: 950px;
}

.turnarounds-module-config .module-section > div {
  display: grid;
  margin: 16px;
  align-items: center;
  gap: 16px;
}

.turnarounds-module-config .module-section .operation-summary {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto;
}

.turnarounds-module-config .module-section .operation-summary span {
  font-size: 12px;
  font-weight: 500;
  color: var(--medium-font-color);
  text-transform: uppercase;
}

.turnarounds-module-config .module-section .operation-summary span .danger {
  color: var(--primary-red);
}

.turnarounds-module-config .module-section .operation-summary .stats {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr auto 1fr 1fr;
  gap: 8px;
}
.turnarounds-module-config .module-section .operation-summary .divider {
  display: grid;
  vertical-align: middle;
}

.turnarounds-module-config .module-section .operation-summary .divider > div {
  display: grid;
  width: 1px;
  height: 75%;
  margin: auto;
  background-color: var(--outline-color);
}
.turnarounds-module-config .module-section .operation-summary .stat {
  display: grid;
  border: 1px solid var(--outline-color);
  border-radius: 8px;
  background-color: var(--overlay-color);
}
.turnarounds-module-config .module-section .operation-summary .stat > div {
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  margin: 4px 10px 8px 10px;
  gap: 4px;
}
.turnarounds-module-config .module-section .operation-summary .stat span {
  font-size: 10px;
  font-weight: 500;
  color: var(--medium-font-color);
  text-transform: uppercase;
}

.turnarounds-module-config .operation-config-container {
  margin-bottom: 16px;
}

.turnarounds-module-config .operation-config {
  display: grid;
  gap: 16px;
}

.turnarounds-module-config .module-section .operation-requirements {
  display: grid;
  gap: 16px;
}

.turnarounds-module-config .module-section .operation-requirements > div {
  display: grid;
  background-color: var(--semi-transparent);
  border-radius: 12px;
}

.turnarounds-module-config .module-section .operation-requirement {
  display: grid;
  gap: 8px;
}
.turnarounds-module-config .module-section .operation-requirement > div {
  display: grid;
  margin: 16px;
  gap: 8px;
}

.turnarounds-module-config .module-section .operation-requirement button {
  padding: 8px;
}
.turnarounds-module-config .module-section .operation-requirement button svg {
  width: 12px;
  height: 12px;
  vertical-align: middle;
}

.turnarounds-module-config .module-section .operation-requirement-info {
  display: grid;
  grid-template-columns: 1fr auto;
  font-size: 18px;
  font-weight: 500;
  align-items: center;
}

.turnarounds-module-config
  .module-section
  .operation-requirement-info
  > div:last-child {
  margin-right: 8px;
}

.turnarounds-module-config .module-section .operation-requirement-slots,
.turnarounds-module-config .module-section .operation-requirement-slots > div {
  display: grid;
  gap: 8px;
}

.turnarounds-module-config .module-section .turnaround-module-slot {
  display: grid;
  background-color: var(--semi-transparent);
  border-radius: 12px;
  font-size: 14px;
  border: 1px solid transparent;
  transition: all 0.25s ease-out;
}
.turnarounds-module-config .module-section .turnaround-module-slot .actions {
  display: grid;
  grid-template-columns: auto auto;
  transition: all 0.25s ease-out;
  gap: 8px;
}
.turnarounds-module-config .module-section .turnaround-module-slot:hover {
  border: 1px solid var(--outline-color);
}
.turnarounds-module-config
  .module-section
  .turnaround-module-slot:hover
  .actions {
  opacity: 1;
}

.turnarounds-module-config .module-section .turnaround-module-slot.empty {
  display: grid;
  border: 1px dashed var(--outline-color);
  border-radius: 12px;
  font-size: 14px;
}

.turnarounds-module-config
  .module-section
  .turnaround-module-slot.empty
  .unassigned {
  color: var(--medium-font-color);
}

.turnarounds-module-config .module-section .turnaround-module-slot > div {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin: 8px;
}

.turnarounds-module-config .module-section .turnaround-module-slot .timestamps {
  font-size: 10px;
  font-weight: 500;
  color: var(--medium-font-color);
  text-transform: uppercase;
}

/** Info **/
.turnaround-module-info {
  display: grid;
  grid-template-columns: 1fr auto 360px;
  align-items: center;
  gap: 8px;
}
.turnaround-module-info .divider {
  display: grid;
  width: 1px;
  height: 100%;
}
.turnaround-module-info .divider > div {
  background-color: var(--outline-color);
  margin: 8px 0;
}
.turnaround-module-info .side-info {
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 1fr;
  gap: 8px;
}
.turnaround-module-info .side-info-row {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 240px;
  gap: 8px;
}
.turnaround-module-info .side-info .stat {
  height: 72px;
}
.turnaround-module-info .turnaround-module-info-stats {
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: start;
  gap: 8px;
}

.turnaround-module-info .turnaround-module-info-stats span.striked {
  text-decoration: line-through var(--medium-font-color) 1px solid;
  margin: 0 4px 0 0;
  color: var(--medium-font-color);
  font-size: 10px;
  font-weight: 500;
}

.turnaround-module-info .stat {
  display: grid;
  background-color: var(--overlay-color);
  border-radius: 8px;
  border: 1px solid var(--outline-color);
  height: 72px;
}
.turnaround-module-info .stat > div {
  display: grid;
  gap: 2px;
  margin: 8px 12px;
  grid-template-rows: auto 1fr;
}
.turnaround-module-info .stat > div > div:first-child {
  font-size: 10px;
  font-weight: 500;
  color: var(--medium-font-color);
  text-transform: uppercase;
}
.turnaround-module-info .stat > div > div:last-child {
  font-size: 14px;
  font-weight: 600;
}
.turnaround-module-info .stat.text-content > div > div:last-child {
  font-size: 10px;
  font-weight: 500;
  color: var(--base-font-color);
}
.turnaround-module-info .turnaround-module-info-stats .divider {
  display: grid;
  vertical-align: middle;
}

.turnaround-module-info .turnaround-module-info-stats .divider > div {
  display: grid;
  width: 1px;
  height: 75%;
  margin: auto;
  background-color: var(--outline-color);
}

.turnaround-module-info .turnaround-module-info-row {
  display: grid;
  grid-template-columns: auto 1fr repeat(4, 96px);
  align-content: center;
  gap: 8px;
}
.turnaround-module-info .turnaround-module-info-row > div:first-child {
  display: grid;
  vertical-align: middle;
  align-content: center;
}
.turnaround-module-info .turnaround-module-info-row > div:first-child > svg {
  margin: 8px;
}

.turnaround-module-info .turnaround-module-info-row .bigstat {
  display: grid;
  align-content: center;
  font-size: 16px;
  font-weight: 600;
}
.turnaround-module-info .turnaround-module-info-row .bigstat .bigstat-detail {
  display: grid;
  font-size: 12px;
  font-weight: 500;
  color: var(--light-font-color);
}

/** Turnaround Summary **/
.turnarounds-module-summary {
  display: grid;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 16px;
}
.turnarounds-module-summary .turnarounds-module-summary-header {
  background-color: var(--darker-bg-color);
  font-size: 14px;
  font-weight: 600;
}

.turnarounds-module-summary .turnarounds-module-summary-row {
  font-size: 14px;
  font-weight: 500;
}
.turnarounds-module-summary .turnarounds-module-summary-grouping {
  background-color: var(--dark-bg-color);
  transition: all 0.25s ease-out;
  border-top: 1px solid var(--outline-darker-color);
  /* cursor: pointer; */
}
.turnarounds-module-summary .turnarounds-module-summary-grouping:hover {
  background-color: var(--intermediate-bg-color);
}
.turnarounds-module-summary .turnarounds-module-summary-header,
.turnarounds-module-summary .turnarounds-module-summary-row {
  display: grid;
  grid-template-columns: 240px repeat(2, 180px) 120px 1fr;
  align-items: start;
}

.turnarounds-module-summary .turnarounds-module-summary-row:first-child {
  align-items: center;
}

.turnarounds-module-summary .turnarounds-module-summary-header > div {
  margin: 12px;
}
.turnarounds-module-summary
  .turnarounds-module-summary-header
  > div:first-child {
  margin-left: 16px;
}
.turnarounds-module-summary .turnarounds-module-summary-row > div {
  margin: 12px;
  transition: all 0.25s ease-out;
}
.turnarounds-module-summary .turnarounds-module-summary-row > div:first-child {
  margin-left: 16px;
}

.turnarounds-module-summary .turnarounds-module-summary-listing {
  display: grid;
  font-size: 12px;
  font-weight: 500;
  color: var(--light-font-color);
}
.turnarounds-module-summary .turnarounds-module-summary-label {
  text-transform: uppercase;
  color: var(--light-font-color);
  font-size: 10px;
  font-weight: 500;
}
.turnarounds-module-summary .turnarounds-module-summary-user {
  color: var(--base-font-color);
  font-size: 12px;
  font-weight: 600;
}

.turnarounds-module-summary .operation-name {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 16px;
}
.turnarounds-module-summary .operation-name-status {
  display: grid;
  align-items: center;
}
.turnarounds-module-summary .operation-name-status svg {
  width: 18px;
  height: 18px;
}
.turnarounds-module-summary .operation-name-content {
  display: grid;
  gap: 2px;
}
.turnarounds-module-summary .operation-name .operation-name-details {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--light-font-color);
}

/** Turnaround module timestamp **/
.turnaround-module-timestamp {
  display: grid;
  border: 1px solid transparent;
  border-radius: 8px;
  transition: all 0.25s ease-out;
  align-items: center;
}
.turnaround-module-timestamp > div {
  display: grid;
  align-items: center;
}
.turnaround-module-timestamp.clickable {
  border: 1px solid var(--outline-color);
  cursor: pointer;
}

.turnaround-module-timestamp.clickable > div {
  grid-template-columns: 1fr auto;
  margin: 4px 6px;
  gap: 4px;
}
.turnaround-module-timestamp.clickable:hover {
  border: 1px solid var(--primary-blue-dim);
  background-color: var(--primary-blue-semi);
}
.turnaround-module-timestamp.clickable .turnaround-module-timestamp-edit {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  opacity: 0.5;
  transition: all 0.25s ease-out;
  border-radius: 50%;
  background-color: var(--light-bg-color);
}
.turnaround-module-timestamp.clickable:hover .turnaround-module-timestamp-edit {
  opacity: 1;
  background-color: var(--primary-blue);
}
.turnaround-module-timestamp.clickable .turnaround-module-timestamp-edit svg {
  width: 10px;
  height: 10px;
}
.turnaround-module-timestamp.clickable
  .turnaround-module-timestamp-edit
  svg
  path {
  fill: var(--base-font-color);
}

.turnaround-module-timestamp .turnaround-module-timestamp-values {
  display: grid;
}
.turnaround-module-timestamp .turnaround-module-timestamp-values > div {
  display: grid;
}

/** Turnaround Service Records **/
.turnarounds-module-service-records {
  display: grid;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 16px;
}
.turnarounds-module-service-records .turnarounds-module-service-records-header {
  display: grid;
  grid-template-columns: 240px repeat(3, 96px) 1fr;
  background-color: var(--darker-bg-color);
  font-size: 14px;
  font-weight: 600;
}
.turnarounds-module-service-records .turnarounds-module-service-records-row {
  display: grid;
  grid-template-columns: 240px repeat(3, 96px) 1fr;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--dark-bg-color);
  transition: all 0.25s ease-out;
  align-items: center;
}
.turnarounds-module-service-records
  .turnarounds-module-service-records-row
  .display-name {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
}
.turnarounds-module-service-records
  .turnarounds-module-service-records-row
  .display-name-values {
  display: grid;
}
.turnarounds-module-service-records
  .turnarounds-module-service-records-row
  .display-name-values
  label {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
}

.turnarounds-module-service-records
  .turnarounds-module-service-records-row
  .toggle {
  display: grid;
  cursor: pointer;
  rotate: -90deg;
  width: 18px;
  height: 18px;
}
.turnarounds-module-service-records
  .turnarounds-module-service-records-row.expanded
  .toggle {
  rotate: 0deg;
}
.turnarounds-module-service-records
  .turnarounds-module-service-records-row
  .toggle
  svg {
  width: 18px;
  height: 18px;
}
.turnarounds-module-service-records
  .turnarounds-module-service-records-row.detail {
  color: var(--light-font-color);
}

.turnarounds-module-service-records
  .turnarounds-module-service-records-row:hover {
  background-color: var(--medium-bg-color);
}
.turnarounds-module-service-records
  .turnarounds-module-service-records-header
  > div
  > div,
.turnarounds-module-service-records
  .turnarounds-module-service-records-row
  > div
  > div {
  margin: 12px 18px;
}
.turnarounds-module-service-records
  .turnarounds-module-service-records-row.detail
  > div
  > div {
  margin: 8px 18px;
}
.turnarounds-module-service-records .turnarounds-module-service-records-name {
  display: grid;
  gap: 2px;
}
.turnarounds-module-service-records
  .turnarounds-module-service-records-details {
  font-size: 10px;
  font-weight: 500;
  color: var(--light-font-color);
  text-transform: uppercase;
}

/** Quantity **/
.quantity-totals {
  display: inline-grid;
  font-size: 12px;
  font-weight: 500;
  color: var(--medium-font-color);
}
.quantity-totals-row {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 4px;
}

.quantity-totals-row > div:first-child {
  text-align: right;
}

.quantity-values {
  display: inline-flex;
  font-size: 12px;
  font-weight: 500;
  color: var(--medium-font-color);
  gap: 12px;
}
.quantity-values .quantity-value {
  display: grid;
  grid-template-columns: auto auto;
  align-items: baseline;
  gap: 4px;
}
.quantity-values .quantity-value > div:first-child {
  font-size: 14px;
  font-weight: 600;
  color: var(--base-font-color);
}
.quantity-values .quantity-value > div:last-child {
  text-transform: lowercase;
}

/** GSE Info **/
.gse-info {
  display: grid;
  margin: 8px;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
}

.gse-info .gse-info-name {
  font-size: 14px;
  font-weight: 600;
}
.gse-info.empty .gse-info-name {
  color: var(--medium-font-color);
}

.gse-info .gse-info-type {
  font-size: 12px;
  font-weight: 500;
  color: var(--medium-font-color);
  text-transform: uppercase;
  margin-bottom: 4px;
}

.gse-info .gse-info-timestamp {
  font-size: 10px;
  font-weight: 500;
  color: var(--medium-font-color);
  text-transform: uppercase;
}

.gse-info .gse-status {
  display: grid;
  border-radius: 50%;
  border: 1px solid var(--pure-white);
  width: 16px;
  height: 16px;
  margin: 4px;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.gse-info .gse-status > div {
  display: grid;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  background-color: var(--pure-white);
}
.gse-info.empty .gse-status > div {
  background-color: var(--lighter-bg-color);
}
.gse-info .gse-status.completed > div {
  background-color: var(--primary-green);
}
.gse-info .gse-status.in-progress > div {
  background-color: var(--primary-yellow);
}

/** Chat module override **/
.turnarounds-module-config .module-section > div.turnaround-chat-history {
  margin: 0;
  overflow: hidden;
  align-items: start;
}

.chat.compact-mode .chat-body {
  height: 600px;
}
.chat.compact-mode .chat-module {
  height: 100%;
  overflow: hidden;
}

/** Turnaround Leads **/
.turnaround-leads {
  display: grid;
  align-items: center;
  border-radius: 8px;
  color: var(--light-font-color);
  transition: all 0.25s ease-out;
  border: 1px solid transparent;
}
.turnaround-leads.active {
  cursor: pointer;
}

.turnaround-leads.active:hover {
  background-color: var(--primary-blue-semi);
  color: var(--base-font-color);
  border: 1px solid var(--primary-blue-dim);
}
.turnaround-leads > div {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  margin: 4px 8px;
  gap: 8px;
}

.turnaround-leads .turnaround-leads-rows {
  display: grid;
  gap: 4px;
  font-size: 12px;
  font-weight: 500;
}

.turnaround-leads .turnaround-leads-rows.trimmed .turnaround-leads-row {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.turnaround-leads .turnaround-leads-actions .action-icon {
  display: grid;
  align-items: center;
  align-content: center;
  border-radius: 50%;
  background-color: var(--medium-bg-color);
  width: 24px;
  height: 24px;
  justify-items: center;
  cursor: pointer;
  opacity: 0;
  transition: all 0.25s ease-out;
}

.turnaround-leads.active:hover .turnaround-leads-actions .action-icon {
  opacity: 1;
}

.turnaround-leads .turnaround-leads-actions .action-icon svg {
  width: 10px;
  height: 10px;
}
.turnaround-leads.active:hover .turnaround-leads-actions .action-icon {
  background-color: var(--primary-blue);
}
.turnaround-leads.active:hover .turnaround-leads-actions .action-icon svg {
  transition: all 0.25s ease-out;
  opacity: 1;
}
.turnaround-leads.active:hover .turnaround-leads-actions .action-icon svg path {
  fill: var(--base-font-color);
}

/** Turnaround Remarks **/
.turnaround-remarks {
  display: grid;
  align-items: center;
  border-radius: 8px;
  color: var(--base-font-color);
  transition: all 0.25s ease-out;
  border: 1px solid transparent;
}
.turnaround-remarks.active:hover {
  background-color: var(--primary-blue-semi);
  color: var(--base-font-color);
  border: 1px solid var(--primary-blue-dim);
}
.turnaround-remarks > div {
  display: grid;
  align-items: center;
  /* margin: 6px; */
}

.turnaround-remarks .turnaround-remarks-content {
  display: grid;
  font-size: 12px;
  font-weight: 500;
}

/* Turnaround edit modal */
.turnaround-edit-modal-content {
  display: grid;
  margin: 0 24px;
  gap: 16px;
  align-content: start;
}

.turnaround-edit-modal-content .empty-state {
  color: var(--base-font-color);
  font-size: 14px;
  font-weight: 500;
}

.turnaround-edit-modal-content .turnaround-edit-modal-row {
  display: grid;
  gap: 8px;
}
.turnaround-edit-modal-content .turnaround-edit-modal-row textarea {
  color: var(--input-font-color);
  background-color: var(--input-bg-color);
  font-size: 14px;
  font-weight: 500;
  font-family: Inter;
  box-sizing: border-box;
  padding: 8px;
  border-radius: 8px;
  width: 100%;
}

.turnaround-edit-modal-content .turnaround-edit-modal-row label {
  font-size: 14px;
  font-weight: 600;
  color: var(--base-font-color);
  margin-bottom: 4px;
}
.turnaround-edit-modal-content
  .turnaround-edit-modal-row
  .turnaround-edit-modal-row-split {
  display: grid;
}
.turnaround-edit-modal-content
  .turnaround-edit-modal-row
  .turnaround-edit-modal-row-note {
  font-size: 10px;
  font-weight: 500;
  color: var(--light-font-color);
  text-transform: uppercase;
}

.turnaround-edit-modal-content .turnaround-edit-slots label {
  font-size: 12px;
  font-weight: 500;
  color: var(--light-font-color);
  margin-bottom: 4px;
  text-transform: uppercase;
}

.turnaround-edit-modal-content .turnaround-edit-slots {
  display: grid;
  border: 1px solid var(--outline-color);
  border-radius: 12px;
  background-color: var(--dark-bg-color);
}
.turnaround-edit-modal-content .turnaround-edit-slots > div {
  display: grid;
  color: var(--base-font-color);
  gap: 8px;
  margin: 12px;
}

.turnaround-module-timestamp-content {
  display: grid;
  margin: 0 16px;
  align-content: start;
  gap: 8px;
}
.turnaround-module-timestamp-content .turnaround-module-timestamp-row {
  display: grid;
  gap: 8px;
  color: var(--base-font-color);
  grid-template-columns: 1fr auto;
  align-items: center;
}

.turnaround-module-timestamp-content
  .turnaround-module-timestamp-row
  > div:first-child {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
}

.turnaround-module-timestamp-content .turnaround-module-timestamp-input {
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr auto;
  align-items: center;
}
.turnaround-module-timestamp-content
  .turnaround-module-timestamp-input.clearable {
  grid-template-columns: 1fr auto auto;
}
.turnaround-module-timestamp-content
  .turnaround-module-timestamp-input
  > div:first-child {
  display: grid;
  border-radius: 8px;
  border: 1px solid var(--outline-color);
}
.turnaround-module-timestamp-content .time-input {
  margin: 4px 8px;
}
.turnaround-module-timestamp-content .time-input select {
  font-size: 14px;
  font-weight: 600;
}
.turnaround-module-timestamp-content .time-input-clear {
  opacity: 0.5;
  transition: all 0.25s ease-out;
  cursor: pointer;
}
.turnaround-module-timestamp-content .time-input-clear:hover {
  opacity: 1;
}

.turnaround-timestamp-modal .messages {
  display: grid;
  grid-template-columns: auto 1fr;
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-red);
  align-items: center;
  gap: 4px;
  margin-top: 2px;
}
.turnaround-timestamp-modal svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

.turnaround-module-timestamp.missing {
  color: var(--primary-yellow);
}

.datetime-range-values {
  display: flex;
  align-items: center;
  grid-template-columns: auto auto auto;
  gap: 8px;
}
.datetime-range-values > div {
  display: grid;
}
.datetime-range-values .datetime-range-values-detail {
  font-size: 10px;
  font-weight: 500;
  color: var(--light-font-color);
}

/** Turnaround page stats **/
.turnaround-page-stats {
  display: grid;
}
.turnaround-page-stats > div {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 16px;
}
.turnaround-page-stats svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}
.turnaround-page-stats .page-stat {
  display: grid;
  grid-template-columns: 12px auto 1fr;
  gap: 4px;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: var(--light-font-color);
}
.turnaround-page-stats .page-stat-value {
  font-weight: 600;
  color: var(--base-font-color);
  margin-left: 2px;
}
.turnaround-progress-ring {
  transform: rotate(-90deg);
}

/** Indicator **/
.turnaround-page-indicator {
  display: grid;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--light-bg-color);
}
.turnaround-page-indicator.complete {
  background-color: var(--primary-green);
}
.turnaround-page-indicator.incomplete {
  background-color: var(--primary-yellow);
}

.turnaround-operation-progress-tooltip {
  display: grid;
  gap: 4px;
}
.turnaround-operation-progress-tooltip .tooltip-row {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 12px;
}

.turnaround-operation-progress-tooltip .tooltip-row.total {
  font-weight: 600;
  color: var(--base-font-color);
}

/** task-info-filters **/
.turnarounds-module .task-info-filters {
  display: grid;
  border: 1px solid var(--outline-color);
  border-radius: 8px;
}
.turnarounds-module .task-info-filters > div {
  display: grid;
  margin: 4px;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  gap: 4px;
}

.turnarounds-module .task-info-filters .task-info-filter {
  display: grid;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.25s ease-out;
}
.turnarounds-module .task-info-filters .task-info-filter:not(.selected):hover {
  background-color: var(--medium-bg-color);
}

.turnarounds-module .task-info-filters .task-info-filter > div {
  display: grid;
  font-size: 12px;
  font-weight: 500;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin: 6px 8px;
}

.turnarounds-module .task-info-filters .task-info-filter-dot {
  display: grid;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.turnarounds-module .task-info-filters .task-info-filter-name {
  margin: 0 8px;
}
.turnarounds-module
  .task-info-filters
  .task-info-filter
  .task-info-filter-name {
  color: var(--dark-font-color);
  transition: all 0.25s ease-out;
}
.turnarounds-module
  .task-info-filters
  .task-info-filter.selected
  .task-info-filter-name {
  color: var(--base-font-color);
}

.turnarounds-module .task-info-filter.operations .task-info-filter-dot {
  background-color: var(--primary-blue);
}
.turnarounds-module .task-info-filter.users .task-info-filter-dot {
  background-color: var(--primary-orange);
}
.turnarounds-module .task-info-filter.gse .task-info-filter-dot {
  background-color: var(--primary-purple);
}
