.progress-ring {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 8px;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: var(--light-font-color);
}
.progress-ring .progress-ring-circles {
  transform: rotate(-90deg);
}
.progress-ring svg {
  vertical-align: middle;
}
.progress-ring circle {
  fill: transparent;
  stroke: var(--pure-white-tint);
  stroke-width: 3;
  stroke-dasharray: 64;
  stroke-dashoffset: 0;
}
.progress-ring circle.secondary {
  fill: transparent;
  stroke: var(--primary-yellow-semi);
  stroke-dashoffset: 64;
}
.progress-ring circle.main {
  fill: transparent;
  stroke: var(--primary-yellow);
  stroke-dashoffset: 64;
}
.progress-ring.complete circle.main {
  fill: transparent;
  stroke: var(--primary-green);
  stroke-dashoffset: 0;
}
