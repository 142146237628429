.date-time-input {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
}
.date-time-input .time-input {
  display: grid;
  color: var(--base-font-color);
  font-size: 14px;
  font-weight: 600;
}

.date-time-input .time-input select {
  font-size: 14px;
  font-weight: 600;
}
